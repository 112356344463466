import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const KystesMucoide = () => (
  <Layout
    title="Kystes mucoïde : Diagnostic et traitement"
    description="Les Kystes mucoïdes sont des petite tuméfactions toujours bénignes siégeant le plus souvent au dos de la dernière articulation des doigts."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Kystes mucoïde" />
        <MainPart title="Qu'est-ce que sont les kystes mucoïdes ?">
          <Typography>
            Les kystes mucoïdes sont de petites tuméfactions (gonflements) qui siègent le plus
            souvent au dos de la dernière articulation des doigts, près de l&apos;ongle
            (articulation interphalangienne distale). Ces tuméfactions sont toujours bénignes.
          </Typography>
        </MainPart>
        <MainPart title="D'où viennent les kystes et où sont-ils situés ?">
          <Typography>
            Les kystes mucoïdes sont d&apos;origine articulaire. Ils sont remplis d&apos;un liquide
            gélatineux et se développent en profondeur (avec risque de déformation de l&apos;ongle)
            et/ou en superficie (risque de fistulisation). On retrouve ce type de kystes au niveau
            des articulations arthrosiques.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes des kystes mucoïdes ?">
          <Typography>
            Le signe clinique le plus fréquent du kyste mucoïde est la présence d&apos;une
            tuméfaction (Figure 1). Avec le temps, la peau en regard du kyste devient de
            plus en plus pellucide (transparente).
          </Typography>
          <Figure
            imageName="figure1-kystesmucoide.jpg"
            caption="Figure 1. Kyste mucoïde de l&apos;articulation inter-phalangienne du pouce"
            currentOpTitle="Kyste mucoîde"
          />
          <Typography>
            L&apos;un des autres symptômes est une déformation de l&apos;ongle, qui est due à la
            compression de la matrice par le kyste (figure 2). Une déformation arthrosique
            du doigt peut également apparaître (bosses, nodules, angulations).
          </Typography>
          <Figure
            imageName="figure2-kystesmucoide.jpg"
            caption="Figure 2. Kyste mucoïde avec déformation de l&apos;ongle en regard"
            currentOpTitle="Kyste mucoîde"
          />
          <Typography>
            En cas de rupture du kyste (fistulisation), une infection de l&apos;articulation
            (arthrite) et parfois de l&apos;os (ostéo-arthrite) peut survenir.
          </Typography>
        </MainPart>
        <MainPart title="Comment est posé le diagnostic qui confirme la présence d'un kyste mucoïde ?">
          <Typography>
            Pour confirmer la présence d&apos;un kyste mucoïde, le patient doit passer un examen
            radiographique. Celui-ci aura pour fonction de rechercher des signes d&apos;arthrose
            sous-jacente (Figure 3).
          </Typography>
          <Figure
            imageName="figure3-kystesmucoide.jpg"
            caption="Figure 3. Importante arthrose au niveau des articulation inter-phalangiennes distales des doigts sur les clichés radiographiques"
            currentOpTitle="Kyste mucoîde"
          />
        </MainPart>
        <MainPart title="Quels traitements en cas de kyste mucoïde ?">
          <Typography>
            La prise en charge thérapeutique dépend de la taille du kyste.
          </Typography>
          <Typography size="mdx" weight="bold">Pour les kystes récents de petite taille :</Typography>
          <Typography>
            Ce type de kyste, sans déformation de l&apos;ongle, demande une simple surveillance.
            L&apos;acte chirurgical (ponction du kyste) est à proscrire, car il aboutit dans la
            plupart des cas à une infection de l&apos;articulation.
          </Typography>
          <Typography size="mdx" weight="bold">Pour les kystes volumineux et/ou fistulisés et/ou avec déformation de l&apos;ongle :</Typography>
          <Typography>
            Pour ces types de kystes, la prise en charge est chirurgicale. Le kyste et la peau en
            regard sont alors retirés par le chirurgien. La perte de substance cutanée est couverte
            soit par un lambeau (la peau voisine est décollée et tournée au-dessus de la perte de
            substance) (Figure 4), soit par une greffe de peau prélevée sur la main ou
            l&apos;avant-bras.
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-kystesmucoide.jpg"
            caption="Figure 4. Dessin schématique représentant la perte de substance cutanée après exérèse d&apos;un kyste mucoïde. La peau adjacente est décollée puis tournée (lambeau) afin de couvrir l&apos;articulation inter-phalangienne distale."
            currentOpTitle="Kyste mucoîde"
          />
          <Typography>
            Si l&apos;articulation présente une arthrose modérée, les becs osseux (ostéophytes)
            sont retirés durant l&apos;intervention. Notez qu&apos;aucun geste n&apos;est
            réalisé sur l&apos;ongle. Ainsi, après l&apos;exérèse du kyste, la repousse de
            l&apos;ongle fera disparaître la déformation.
          </Typography>
          <Typography>
            En cas d&apos;arthrose majeure et douloureuse, un blocage de l&apos;articulation
            (arthrodèse) est nécessaire. En effet, l&apos;excision du kyste ne suffit pas à
            éliminer les douleurs. Cette intervention chirurgicale nécessite la mise en
            place de matériel (vis ou broche) et une immobilisation de l&apos;articulation.
            Ce blocage de l&apos;articulation a peu de conséquences fonctionnelles, car
            elle est réalisée le plus souvent sur une articulation déjà détruite.
          </Typography>
          <Typography>
            L&apos;acte chirurgical est majoritairement réalisé sous anesthésie loco-régionale
            (seul le bras du patient est endormi), en ambulatoire (pas d&apos;hospitalisation).
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires après une opération chirurgicale pour des kystes mucoïdes ?">
          <div className="flex flex-col">
            <Typography>
              Pour ce type d&apos;intervention sur cette maladie, un petit pansement est réalisé
              tous les 2-3 jours pendant 14 jours. Le 14eme jour, les fils sont retirés. En cas
              d&apos;arthrodèse, l&apos;articulation est immobilisée pendant 1 à 3 mois, selon
              le montage.
            </Typography>
            <Typography>
              La rééducation post-opératoire n&apos;est pas forcément nécessaire.
            </Typography>
          </div>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles ?">
          <Typography>
            Aucun acte chirurgical n&apos;est sans risque de complication secondaire. Ainsi,
            en cas de chirurgie pour un kyste mucoïde, on peut classer les complications en
            deux catégories.
          </Typography>
          <Typography size="mdx" weight="bold">Les complications non spécifiques :</Typography>
          <Typography>
            Parmi ce type de complication, on peut citer :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                L&apos;infection sur site opératoire qui peut nécessiter une intervention de
                nettoyage associée à la prise d&apos;antibiotique.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un hématome qui peut nécessiter une évacuation en cas de menace cutanée ou de
                compression nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse avec
                transpiration), puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont possibles
                (douleur et/ou raideur des articulations des doigts et/ou poignet et/ou épaule).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les accidents liés à l&apos;anesthésie.
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <Typography>
            Pour ces complications, on retrouve :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                La récidive, qui est la complication la plus fréquente (5 à 10 % des cas).
                L&apos;utilisation d&apos;un lambeau ou d&apos;une greffe de peu diminue
                considérablement ce risque.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les difficultés de cicatrisation, la mort de la greffe de peau ou du lambeau.
                Néanmoins, il s&apos;agit d&apos;une complication rare favorisée par le tabagisme.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                L&apos;enraidissement post-opératoire. Le plus souvent en rapport avec
                l&apos;arthrose sous-jacente plutôt qu&apos;avec l&apos;acte chirurgical.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La séquelle esthétique unguéale. La déformation de l&apos;ongle peut persister
                malgré le traitement du kyste.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Des douleurs sur la cicatrise, qui peuvent durer plusieurs mois. La régression
                est le plus souvent spontanée.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default KystesMucoide;
